@import url(https://fonts.googleapis.com/css2?family=Montserrat&family=Outfit:wght@100;300;400;500;600;700;800&display=swap);
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

h1,h4,p,a {
  color: #fff;
}

body {
  margin: 0;
  font-family: 'Outfit', 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  background: #000;
}
ul {
  list-style-type: none;
}

a {
  text-decoration: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.btn {
  padding: 12px 32px;
  font-size: 1.2rem;
  text-transform: uppercase;
  background: transparent;
  color: #fff;
  border: 1px solid #fff;
  cursor: pointer;
}

.btn-light {
  background: rgba(255,255,255, .2);
}

.btn:hover {
  background: rgba(255,255,255, .2);
}
.footer {
    width: 100%;
    padding: 6rem 0;
    background: rgba(0,0,0,.8);
}

.footer-container {
    max-width: 1140px;
    margin: auto;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 40px;
}

.footer .left {
    height: 100%;
    max-width: 100%;
    display: flex;
    flex-direction: column;
    padding: 1rem;
}

.footer .right {
    height: 100%;
    max-width: 100%;
    display: flex;
    flex-direction: column;
    padding: 1rem;
    margin: auto;
}

.highlight:hover {
    color: #a4a3ac;
}

.highlight {
    color: #ffffff;
    opacity: 0.99;
    margin-right: 1rem;
}

.footer h4 {
    font-size: 1.4rem;
    padding: 1rem 0;
}

.footer p {
    font-size: 1.2rem;
    padding: 1rem 0;
}

.footer .location {
    display: flex;
    align-items: center;
}

.footer .location p {
    padding-bottom: .5rem;
}

.footer .location h4 {
    padding-top: 0;
}


@media screen and (max-width:640px) {
    .footer-container {
        grid-template-columns: 1fr;
    }

    .right .social {
        margin: auto;
    }
}







.hero-img {
    background: rgba(0,0,0,.3);
    height: 50vh;
    width: 100%;
    position: relative;
}

.hero-img:before {
    content:'';
    background: url(/static/media/track2.4d22d39b.jpg) no-repeat center center/cover;
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
}

.hero-img .heading {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.hero-img h1 {
    font-size: 2.4rem;
}

.hero-img p {
    font-size: 1.4rem;
}

@media screen and (max-width:640px) {
    .hero-img h1 {
        font-size: 2rem;
    }
}

.header {
    position: fixed;
    width: 100%;
    height: 90px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;
    z-index: 10;
}

.header-bg {
    background-color: rgba(0,0,0, .85);
    transition: .5s;
}

.nav-menu {
    display: flex;
}

.nav-menu li {
    padding: 0 1rem;
}

.nav-menu a {
    font-size: 1.2rem;
    font-weight: 500;
}

.hamburger {
    display: none;
}

@media screen and (max-width:1240px) {
    .hamburger {
        display: block;
    }

    .nav-menu {
        position: absolute;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        top: 0;
        left: -100%;
        text-align: center;
        width: 100%;
        height: 100vh;
        transition: 0.3s;
        z-index: -1;
        background: rgba(0,0,0, .9);
    }

    .nav-menu.active {
        left: 0;
    }

    .nav-menu li {
        padding: 1rem 0;
    }

    .nav-menu a {
        font-size: 2rem;
    }
}








label {
    color: #eee;
}

form {
    display: flex;
    flex-direction: column;
    padding: 1rem;
    margin: auto;
    max-width: 600px;
}

form label {
    margin-bottom: .5rem;
}

form input, textarea {
    margin-bottom: 1rem;
    padding: 10px 18px;
    font-size: 1.2rem;
    font-family: 'Outfit', sans-serif;
    background: rgba(255,255,255,.1);
    border-color: rgba(255,255,255,.1);
    color: #f4f4f4;
}

#video {
    position: fixed;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: -1;
}

.hero {
    height: 100vh;
    width: 100%;
    object-fit: contain;
}

.hero .content {
    text-align: center;
    position: absolute;
    margin: auto;
    width: 100%;
    height: 100%;
    top: 40vh;
}

.hero .content h1 {
    font-size: 4rem;
    color: #fff;
}

.hero p {
    font-size: 1.8rem;
    font-weight: 200;
    text-transform: uppercase;
    margin-bottom: 1.6rem;
    color: #fff;
}

.hero .btn {
    margin: 1rem .2rem;
}

@media screen and (max-width:640px) {
    .content h1 {
        font-size: 3rem;
    }

    .content p {
        font-size: 1.4rem;
        margin-bottom: 1.6rem;
    }
}












.pricing {
    width: 100%;
    padding: 6rem 1rem;
    background-color: #000;
}

.card-container {
    max-width: 1140px;
    margin: auto;
    display: grid;
    grid-gap: 40px;
    grid-template-columns: repeat(3, 1fr);
}

.card-container .card {
    border: 1px solid #eee;
    color: #eee;
}

.card {
    text-align: center;
    padding: 1rem;
}

.card:hover {
    background-color: #141414;
}

.card h3 {
    font-size: 1.4rem;
    padding: 1rem;
}

.card .bar {
    border-bottom: 1px solid #eee;
    width: 10%;
    margin: 1.5rem auto;
    display: block;
}

.card .btc {
    font-size: 4rem;
    font-weight: 600;
    padding: 1rem;
}

.pricing p {
    color: #eee;
    padding: 1rem 0;
    font-size: 1.2rem;
}

.card .btn {
    display: block;
    width: 80%;
    margin: 2rem auto;
}

@media screen and (max-width: 980px) {
    .card-container {
        max-width: 90%;
        margin: auto;
        grid-template-columns: 1fr;
    }
}




.training {
    width: 100%;
    margin: 3rem 0;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    height: 100%;
}

.training .left {
    text-align: center;
    margin: auto;
    padding: 1rem 4rem;
    max-width: 600px;
}

.training .left p {
    margin: 1.2rem 0;
}

.training .right {
    width: 100%;
}

.right .img-container {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    position: relative;
    align-items: center;
    text-align: center;
}

.right .img {
    max-width: 60%;
    border: 1px solid #333;
}

.right .top {
    grid-column: 1 / span 8;
    grid-row: 1;
    padding-top: 20%;
    z-index: 6;
}

.right .bottom {
    grid-column: 4/ -1; 
    grid-row: 1;
    z-index: 5;
}

@media screen and (max-width: 1140px) {
    .training {
        grid-template-columns: 1fr;
    }

    .training .img {
        max-width: 80%;
    }

}






